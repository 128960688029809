import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { cleanFilename, downloadContent } from '@lib/dom/download';
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { DXXData, RockSizePassingPercent, User, fragQuery } from '@models';
import { math } from '@lib/math';
import { chart } from '@lib/angular/chart';
import { op } from '@lib/rxjs';
import { generateChartLines } from '@app/new-ui/chart-transforms';
import { ReportComponent } from '@app/utilities/report/report.component';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from '@lib/date-time';
import { DialogService } from '@app/old-ui/dialog/dialog.service';

@Component({
  selector: 'frag-camera-report',
  templateUrl: './frag-camera-report.component.html',
  styleUrls:['./frag-camera-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FragCameraReportComponent {
    constructor(
        public fragQuery: FragQueryService,
        public user:User,
        private dialog:DialogService,
        private change:ChangeDetectorRef,
        private readonly route: ActivatedRoute,
    ) { }

    @ViewChild(ReportComponent) reportComponent: ReportComponent

    public ngOnInit(): void {
      const fragment = JSON.parse(this.route.snapshot.fragment);
      if (fragment?.custom) {
          this.fragQuery.duration$.next('custom');
          if (Array.isArray(fragment.custom)) {
              this.fragQuery.begin$.next(
                  new DateTime(fragment.custom[0] ?? DateTime.now())
              );
              this.fragQuery.end$.next(
                  new DateTime(fragment.custom[1] ?? DateTime.now())
              );
          }
      }
      if(fragment?.device){
          this.fragQuery.devices$.subscribe(devices=>[
              devices.forEach(device=>{
                  if(device.id===fragment.device){
                      this.fragQuery.device$.next(device)
                  }
              })
          ])
      }
  }

    public render=false;
    public fileType: string = null;
    public secondaryTables:boolean = true;
    public readonly debounce$ = new BehaviorSubject(null);
    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly shifts$ = this.fragQuery.shifts$;
    public readonly shift$ = this.fragQuery.shift$;
    public readonly device$= this.fragQuery.device$;
    public readonly timeRange$= this.fragQuery.timeRange$;
    public readonly duration$ = this.fragQuery.duration$;
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly durationOptions = this.fragQuery.durationOptions;
    public readonly psddata$=new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Interval[]>(null)
    public readonly intervals$ = new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Interval[]>([]);
    
    toggleFileType(type: string) {
        this.fileType = type;
    }

    public async downloadCsv() {
        this.debounce$.next(
            'just waiting \n if you wish to change your mind....'
        );
        const dateTimeFormat: Intl.DateTimeFormatOptions = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            hourCycle: 'h23',
            minute: '2-digit',
            second: '2-digit',
        };
        this.debounce$.next('Fetching Data, Please wait....');
        const input = await this.fragQuery.exportTimelineSieveSizesAtPercents();
        if (input && input.intervals && input.percents && this.fileType !== null) {
            const percents = input.percents.slice().sort((a, b) => a - b);
            let tsrows: string[] = [];
            for (let i = 0; i < 20; i++) {
                tsrows.push(`TS${i + 1}`);
                tsrows.push(`PP${i + 1}`);
            }
            const csvRows: (string | number)[][] = [
                ['Begin Time', 'End Time', ...percents.map((v) => `D${v}`), ...tsrows]
            ];

            input.intervals.forEach(interval => {
                let psdData: number[] = [];
                interval.psd.forEach((psd,index)=> {
                    const passing_percent = (psd.passing_percent && psd.passing_percent !== 0) ? psd.passing_percent : (index > 0 ? interval.psd[index - 1].passing_percent : 0);
                    psd.passing_percent=passing_percent;
                    psdData.push(parseFloat(psd.size.toFixed(2)));
                    psdData.push(parseFloat(passing_percent.toFixed(2)));
                })
                const row: (string | number)[] = [
                    interval.begin.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
                    interval.end.toLocaleString(undefined, dateTimeFormat).replace(',', ''),
                    ...interval.data.map((v) => typeof v === 'number' ? Math.round(v) : ''),
                    ...psdData,
                ];
                csvRows.push(row);
            });

            const csv = csvRows.map((row) => row.join('\t')).join('\n');
            downloadContent(
                cleanFilename('Sieve sizes at percents', 'csv', ' '),
                csv
            );
        }
    }

    toggleSecondaryTables(show:boolean){
        this.change.detectChanges();
        this.secondaryTables=show;
    }

    async warnLargePdf():Promise<boolean|null>{
        if(await this.dialog.confirm({message:`The file may be too large and require significant system's processing memory, Are you sure.??`})){
            this.render=true;
            return true;
        }else{
            this.dialog.message('Going back Shortly.......')
            window.self.close();            
            this.render=false;
            return false;
        }
    }
    
    ngOnDestroy() {
        this.fileType = null;
    }

    public settings={
        includeSwebrec:true,
        includeKuzRam:true,
        includeStatistics:true,
        includeCustomSieveSizes:true,
    }

    public readonly chartLines$: Observable<chart.line.Series[]> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.exportTimelineSieveSizesAtPercents$,
    ]).pipe(
        op.debounceTime(0),
        op.switchMap(([percents, input]) => from((async () => {
            if (input) {
                if (input.intervals.length > 250) {
                    await this.warnLargePdf();
                    this.secondaryTables = false;
                    this.dialog.message('If the target intervals are more, they will be hidden. In case you want them, you can select <b>"Show"</b> in <b>"secondary Tables"</b> selection option.<br> Also make sure to check if all pages are rendered properly before downloading')
                } else {
                    this.render = true;
                }
                this.psddata$.next(input.intervals);
                this.generateTablesData(input, percents);
            }
            return generateChartLines(percents, input);
        })())),
        op.shareReplay(1)
    );

    tableDataMap: Map<string,  {
        dataArr: math.Vec2Like[];
        percentArr: math.Vec2Like[];
        dxxArr: DXXData[];
    }> = new Map();

    secondaryTablesMap:Map<string,TemplateRef<HTMLElement>[]> = new Map();

    setSecondaryTables(begin:string,table:TemplateRef<HTMLElement>[]){
        this.secondaryTablesMap.set(begin,table);
    }

    generateTablesData(input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string>): { input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string> } {
        if (input === undefined) {
            return undefined;
        }
        let obj = transform(input, percents);
        if (obj) {
            for (const entry of obj.entries()) {
                const [key, value] = entry;
                this.tableDataMap.set(key,fragmentationChartOptions(value.rockSizes, value.rockSizeDetails, value.dxxValues))
            }
        }
        return { input, percents };
    }
}

export function transform(data: fragQuery.timeline.sieveSizesAtPercents.Response | undefined, percents: Map<number, string>): Map<string, { rockSizes: Array<number>, rockSizeDetails: Array<RockSizePassingPercent>, dxxValues: Array<DXXData>}>{
    if (data) {
        const datamap: Map<string, { rockSizes: Array<number>, rockSizeDetails: Array<RockSizePassingPercent>, dxxValues: Array<DXXData> }> = new Map();
        data.intervals.forEach(interval => {
            const sievesizes: Array<number> = [];
            interval.psd.forEach(psd => {
                sievesizes.push(psd.size);
            });
            const dxxvalues: Array<DXXData> = [];
            let counter = 1;
            percents.forEach((percent, index) => {
                dxxvalues.push({
                    label: `D${index}`,
                    value: interval.data[counter],
                    index: index,
                    color: percent,
                    canDelete: false
                });
                counter++;
            });
            let result = { rockSizes: sievesizes, rockSizeDetails: interval.psd, dxxValues: dxxvalues };
            datamap.set(interval.begin.toString(), result);
        });
        return datamap;
    }
    return null;
}

export function fragmentationChartOptions(
	rockSizes:Array<number>,
	rockSizeDetails:Array<RockSizePassingPercent>,
	dxxValues:Array<DXXData>,
):{dataArr:math.Vec2Like[],percentArr:math.Vec2Like[],dxxArr:DXXData[]}{
		const percentArr:math.Vec2Like[] = [];
		const dataArr:math.Vec2Like[] = []; // in unit
		let maxPassingPercent:number=0,tickInterval:number;

		rockSizes=rockSizes.sort((a,b)=>a-b);
		const rocksizeString:string[]=[];
		rockSizes.map(rocksize=>{
			rocksizeString.push(rocksize.toString())
		})
		let prev;
		rockSizeDetails.forEach((rockSizeDetail,index)=>{
                const convertedRockSize = parseFloat(rockSizeDetail.size.toFixed(4));
                if(index>0){
                    prev=rockSizeDetails[index-1].passing_percent
                }else{
                    prev=0;
                }
                if(rockSizeDetails[index-1]){
                    rockSizeDetail.passing_percent=(rockSizeDetail.passing_percent&&rockSizeDetail.passing_percent!=0)?rockSizeDetail.passing_percent:(rockSizeDetails[index-1].passing_percent?rockSizeDetails[index-1].passing_percent:0);
                }
                percentArr.push({
                    x: convertedRockSize,
                    y: parseFloat((rockSizeDetail.passing_percent-prev).toFixed(2))
                })
    
                maxPassingPercent=parseFloat((rockSizeDetail.passing_percent-prev).toFixed(2))>maxPassingPercent?parseFloat((rockSizeDetail.passing_percent-prev).toFixed(2)):maxPassingPercent
    
                dataArr.push({
                    x: convertedRockSize,
                    y: rockSizeDetail.passing_percent
                });
		});
        return {dataArr,percentArr,dxxArr:dxxValues}
}
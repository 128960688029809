import { Component, Input } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { combineInput } from '@app/new-ui/analysis/analysis.component';
import { combineLatest, firstValueFrom } from 'rxjs';
import { DataIntervalTagEditDialog } from '@app/shared/edit-dialog/data-interval-tag/data-interval-tag-edit-dialog.component';
import { Observable } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { DataInterval, fragQuery } from '@models';
import { op } from '@lib/rxjs';
import { DialogService } from '../dialog.service';

@Component({
	selector: 'dialog-remove-data-interval',
	templateUrl: './dialog-remove-data-interval.component.html',
	styleUrls: ['./dialog-remove-data-interval.component.scss']
})
export class DialogRemoveDataIntervalComponent {
	static readonly config: MatDialogConfig<Input> = {
		autoFocus: false,
		disableClose: true,
	};
	static readonly output: boolean;

	constructor(
		private readonly host: MatDialogRef<DataIntervalTagEditDialog, boolean>,
		private readonly fragQuery: FragQueryService,
		private dialog: DialogService,
	) {}

	public readonly dataIntervals$: Observable<fragQuery.timeline.sieveSizesAtPercents.Interval[]> = combineLatest([
		this.fragQuery.timelineSieveSizesAtPercents$,
		this.fragQuery.addOnTimelineSieveSizesAtPercents$
	]).pipe(
		op.debounceTime(0),
		op.tap(([input, addOn]) => combineInput(input, addOn, this.fragQuery.addDataAt.value)),
		op.map(([input]) => {
			return input ? input.intervals : null
		}),
		op.shareReplay(1)
	);

	public selectedRecords = new Set<fragQuery.timeline.sieveSizesAtPercents.Interval>;

	public async selectAll(v:boolean){
		if(v){
			const records=await firstValueFrom(this.dataIntervals$);
			for(const record of records)
				this.selectedRecords.add(record);
		}else{
			this.selectedRecords.clear();
		}
	}

	public async selectRecord(selected:boolean,record: fragQuery.timeline.sieveSizesAtPercents.Interval) {
		if (selected){
			this.selectedRecords.add(record);
		}else {
			this.selectedRecords.delete(record);
		}
	}

	public valid() {
		if(this.selectedRecords.size){
			return true;
		}else {
			return false;
		}
	}

	public cancel() {
		this.host.close(false);
	}

	public async remove() { 
		const dataIntervalIds = this.selectedRecords.map(interval => {
			return interval.id
		})
		
		if(await this.dialog.confirm({message:`The action is irreversible, Are you sure to remove the selected ${dataIntervalIds.size} data intervals ?`})){
			await this.dialog.await(this.fragQuery.removeDataIntervals([...dataIntervalIds]));
			this.dialog.closeAll()
		};
	}
}

<div *ngIf="(chartLines$ | async) === undefined" class="container flex-column center middle full-width full-height">
    <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
</div>

<report class="full-height report" [orientation]="'landscape'"
    title="Fragmentation Camera Report">
    <div controls>
        <div>
            <mat-form-field class="full-width">
                <mat-label>Duration</mat-label>
                <mat-select [value]="duration$ | async" [disabled]="(chartLines$ | async) === undefined"
                    (valueChange)="duration$.next($event)">
                    <mat-option *ngFor="let opt of durationOptions|async; let index = index" [value]="opt.key">
                        {{ opt.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <ng-container *ngIf="(duration$ | async) === 'shifts'">
            <mat-form-field class="full-width">
                <mat-label> Shift </mat-label>
                <mat-select [disabled]="(chartLines$ | async) === undefined" [value]="shift$ | async" (valueChange)="shift$.next($event)" >
                    <mat-option *ngFor="let shift of shifts$ | async" [value]="shift">
                        {{ shift.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="(duration$ | async) === 'custom'">
            <div>
                <mat-form-field class="full-width">
                    <mat-label>Start Date</mat-label>
                    <date-picker [disabled]="(chartLines$|async)===undefined" [value]="begin$ | async"
                        (valueChange)="begin$.next($event)">
                    </date-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>End Date</mat-label>
                    <date-picker [disabled]="(chartLines$|async)===undefined" [value]="end$ | async"
                        (valueChange)="end$.next($event)">
                    </date-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>Start Time</mat-label>
                    <time-picker [disabled]="(chartLines$|async)===undefined" [value]="begin$|async"
                        (valueChange)="begin$.next($event)">
                    </time-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>End Time</mat-label>
                    <time-picker [disabled]="(chartLines$|async)===undefined" [value]="end$|async"
                        (valueChange)="end$.next($event)">
                    </time-picker>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container>
            <mat-form-field class="full-width">
                <mat-label> Secondary Tables </mat-label>
                <mat-select [disabled]="(chartLines$ | async) === undefined" [value]="secondaryTables" (valueChange)="toggleSecondaryTables($event)">
                    <mat-option [value]="true">
                        Show
                    </mat-option>
                    <mat-option [value]="false">
                        Hide
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>
    <ng-container *ngIf="(chartLines$|async)" pages>
        <div *ngIf="((psddata$|async).length > 700)" pages>
            <div ngProjectAs="report-page">The pdf will be too large please reduce the time ranges to shorten the pdf length</div>
        </div>
        <ng-container *ngIf="(((psddata$|async).length < 700))" pages>
                <report-page-title ngProjectAs="report-page" title="Fragmentation Camera Report">
                </report-page-title>
                <report-page-timeline ngProjectAs="report-page"
                    *ngIf="(timeRange$|async)"
                    [timelineData]="chartLines$|async"
                    [startDate]="(timeRange$|async).begin"
                    [endDate]="(timeRange$|async).end">
                </report-page-timeline>
                <cdk-virtual-scroll-viewport orientation="horizontal" appendOnly itemSize="1" class="">
                        <ng-container ngProjectAs="report-page" *cdkVirtualFor="let interval of (psddata$|async);let i=index">
                            <report-page>
                                <ng-container class="flex-column full-height">
                                    <report-header [subTitle]="(device$|async).name"></report-header>
                                    <div class="full-height">
                                        <div class="flex-percent report-box ">
                                            <div class="container-67 flex-column">
                                                <image-container
                                                    [imageSrc]="interval.imageUrl"
                                                    [beginDate]="interval.begin"
                                                    [endDate]="interval.end"
                                                    class="half-height"
                                                    [slabs]="interval.slabs"
                                                    [thresholdCount]="interval.thresholdCount" 
                                                    [dynamicClass]="{'red-border': interval.thresholdCount,'yellow-border': interval.slabs}">
                                                </image-container>
                                                <psd-chart class="container half-height push-up" 
                                                    [index]="i"
                                                    [pointSize]="'small'"
                                                    [dxxLabels]="false"
                                                    [data]="psddata$|async"
                                                    [legends]="false">
                                                </psd-chart>
                                            </div>
                                            <div class="tables container-33">
                                                <analysis-table class="container" [size]="'small'" [direction]="'vertical'" [height]="'normal'"
                                                    [dxxArr]="this.tableDataMap.get(interval.begin.toString()).dxxArr"
                                                    [dataArr]="this.tableDataMap.get(interval.begin.toString()).dataArr"
                                                    [percentArr]="this.tableDataMap.get(interval.begin.toString()).percentArr"
                                                    [primaryTableRows]="30-(newPercents$|async).size"
                                                    [secondaryTableRows]="34"
                                                    (secondaryTables)="setSecondaryTables(interval.begin.toString(),$event)">
                                                </analysis-table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </report-page>
                            <ng-container *ngIf="secondaryTables">
                                <report-page *cdkVirtualFor="let chunk of this.secondaryTablesMap.get(interval.begin.toString()) | chunk: 4" [footer]="true" class="landscape">
                                    <report-header [subTitle]="(device$|async).name"></report-header>
                                    <div class="full-height full-width flex-column">
                                        <div class="flex-row container psd-chart-container">
                                            <div class="table-container" *ngFor="let table of chunk">
                                                <ng-container *ngTemplateOutlet="table"></ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </report-page>
                            </ng-container>
                        </ng-container>
                </cdk-virtual-scroll-viewport>
        </ng-container>
    </ng-container>
</report>
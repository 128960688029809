import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PendingRequestService } from './pending-request.service';

@Injectable()
export class PendingRequestInterceptor implements HttpInterceptor {
  private pendingRequests = 0;

  constructor(private pendingRequestService: PendingRequestService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isFragQueryRequest = request.url.includes('quantity');
    if (isFragQueryRequest) {
      this.pendingRequestService.setFragQueryPendingStatus(true);
    }

    this.pendingRequests++;
    this.pendingRequestService.setPendingRequestsStatus(this.pendingRequests > 0);

    return next.handle(request).pipe(
      finalize(() => {
        this.pendingRequests--;
        if (isFragQueryRequest) {
          this.pendingRequestService.setFragQueryPendingStatus(false);
        }
        this.pendingRequestService.setPendingRequestsStatus(this.pendingRequests > 0);
      })
    );
  }
}

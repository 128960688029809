import { Component,EventEmitter,Input,Output,TemplateRef,ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject, filter, interval, map } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { fadeIn } from './camera-snapshot.animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { fragQuery } from '@models';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { WebClient } from '@injectables';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'camera-snapshot',
    templateUrl: './camera-snapshot.component.html',
    styleUrls: ['./camera-snapshot.component.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [useAnimation(fadeIn)]),
        ]),
    ],
})
export class CameraSnapshotComponent<
  Instance extends {
    id: number;
    name?: string;
  },
  RestTypes extends WebClient.RestTypes
> {
  [x: string]: any;
  constructor(
    private readonly fragQuery: FragQueryService,
    public dialogue: MatDialog,
    protected readonly dialog: DialogService,
    private readonly webClient: WebClient,
    private http: HttpClient
  ) {}

  @Input() imgSrc: string = '';
  @Input() snapType: string = 'home';
  @Input() totalIntervals = -1;
  @Input() slabCount: number = 0;

    color: ThemePalette = 'primary';
    checked = false;
    disabled = false;
    defaultImageSrc: string = '../../../assets/image_not_found.jpg';
    imageLoadError: boolean = false;
    imageLoading: boolean = true;
    selectedImageIndex: number | null = null;

    onImageLoad() {
        this.imageLoading = false;  // Set image loading to false when the image has loaded
    }

    @Input()
    get intervals() {
        return this.intervals$.value;
    }
    set intervals(v) {
        this.intervals$.next(v);
    }

    @Input()
    get currentInterval() {
        return this.currentInterval$.value;
    }
    set currentInterval(v) {
        this.currentInterval$.next(v);
    }

    @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() refreshClick: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('fullimgtemplate', { static: false })
    fullimgtemplate!: TemplateRef<any>;

    public readonly intervals$ = new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Interval[]>([]);
    public readonly currentInterval$ = new BehaviorSubject(null);
    public readonly device$ = this.fragQuery.device$;

    public intervalsArray: fragQuery.timeline.sieveSizesAtPercents.Interval[] = [];
    public imgsrc: string = '';
    public currentSlide = 0;
    public showInterval = false;
    ngOnChanges() {
        this.imageLoading=true;
        this.intervals$.subscribe((intervals) => {
            this.intervalsArray = intervals
        });
    }

    public readonly currentUrl = this.currentInterval$.pipe(
        filter((interval) => interval !== null),
        map((interval) => {
            this.showInterval = true;
            this.imgsrc = interval?.imageUrl;
            this.imageLoadError = false;
            return interval?.imageUrl;
        })
    );

    refresh() {
        this.imageLoading=true;
        this.showInterval = false;
        this.refreshClick.emit();
    }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
    
        if (previous >= 0 && this.intervalsArray[previous]) {
            this.currentSlide = previous;
            this.showInterval = false;
            this.imageLoadError = false;
            this.imageLoading = true;
            this.previousClick.emit();
        } else if (previous < 0) {
            this.currentSlide = this.totalIntervals !== -1 ? this.totalIntervals - 1 : this.fragQuery.intervalsLength.value - 1;
        }
    }

    onImageLoadError() {
        this.imageLoadError = true;
        this.imgsrc = this.defaultImageSrc;
    }

    onNextClick() {
        const next = this.currentSlide + 1;

        if (next < this.intervalsArray.length && this.intervalsArray[next]) {
            this.currentSlide = next;
            this.showInterval = false;
            this.imageLoadError = false;
            this.imageLoading = true;
            this.nextClick.emit();
        }
    }

    showFullImg(src?: string) {
        if (src) {
            this.imgsrc = src;
        }
        const dialogRef = this.dialogue.open(this.fullimgtemplate, {
            width: '100%',
        });
    }

    closeFullImg() {
        this.dialogue.closeAll();
    }


  downloadImage() {
    let downloadUrl: fragQuery.timeline.sieveSizesAtPercents.Interval = null;
    if (this.showInterval == true) {
      downloadUrl = this.currentInterval$.value;
    } else {
      downloadUrl = this.intervalsArray[this.currentSlide];
    }
    if (downloadUrl && downloadUrl.imageUrl) {
      const anchor = document.createElement('a');
      anchor.href = downloadUrl.imageUrl;
      anchor.download = 'image';
      anchor.click();
    }
  }

  public async delete() {
    try {
      let interval = this.intervalsArray[this.currentSlide];
      if (this.showInterval == true) {
        interval = this.currentInterval$.value;
      } else {
        interval = this.intervalsArray[this.currentSlide];
      }
      
      const confirmed = await this.dialog.confirm({
        message: `Are you sure you want to delete this interval?`,
      });

      if (confirmed) {
        await this.dialog.await(
          this.fragQuery.removeDataIntervals([interval.id])
        );        
      }
    } catch (error) {
      alert('Failed to delete intervals. Please try again.');
    }
  }
}

import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { DateTime } from '@lib/date-time';
import { fragQuery, User } from '@models';

@Component({
  selector: 'data-interval-selector',
  templateUrl: './data-interval-selection.component.html',
  styleUrls: ['./data-interval-selection.component.scss']
})
export class DataIntervalSelectionComponent {

  constructor(
    public dialogue: MatDialog,
    private user: User,
    protected readonly dialog: DialogService,
  ) { }

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    this._checked = value;
  }

  @Input()
  get interval(): fragQuery.timeline.sieveSizesAtPercents.Interval {
    return this._interval;
  }
  set interval(value: fragQuery.timeline.sieveSizesAtPercents.Interval) {
    this._interval = value;
  }

  @Output() selected: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _checked: boolean = false;
  public _interval: fragQuery.timeline.sieveSizesAtPercents.Interval;
  @ViewChild('fullimgtemplate', { static: false })
  fullimgtemplate!: TemplateRef<any>;

  getFormattedDateTime(): string {
    const formatMap: Record<string, string> = {
      'Month DD YYYY_h11': 'MMM dd yyyy hh:mm:ss a',
      'MM/DD/YY_h11': 'MM/dd/yy hh:mm:ss a',
      'YY/MM/DD_h11': 'yy/MM/dd hh:mm:ss a',
      'DD/MM/YY_h11': 'dd/MM/yy hh:mm:ss a',
      'DD/MM/YYYY_h11': 'dd/MM/yyyy hh:mm:ss a',
      'Month DD YYYY_h23': 'MMM dd yyyy HH:mm:ss',
      'MM/DD/YY_h23': 'MM/dd/yy HH:mm:ss',
      'YY/MM/DD_h23': 'yy/MM/dd HH:mm:ss',
      'DD/MM/YY_h23': 'dd/MM/yy HH:mm:ss',
      'DD/MM/YYYY_h23': 'dd/MM/yyyy HH:mm:ss',
    };
    const formatKey = `${this.user.dateFormat}_${this.user.timeFormat}`;
    return formatMap[formatKey];
  }

  showFullImg(src?: string) {
    this.dialogue.open(this.fullimgtemplate, {
      width: '100%',
    });
  }

  onCheck() {
    this._checked = !this._checked;
    this.selected.emit(this._checked)
  }

  closeFullImg() {

  }
}

<div mat-dialog-content>
  <div class="title">
    Live Tag Settings
	</div>
	<div class="flex-column">
    <label>Tolerance Spec</label>
    <mat-form-field appearance="outline">
      <input matInput />
    </mat-form-field>
    <label>Tolerance Spec</label>
    <mat-form-field appearance="outline">
      <input matInput />
    </mat-form-field>
    <label>Bench Height</label>
    <mat-form-field appearance="outline">
      <input matInput />
    </mat-form-field>
    <label>Bench Seam</label>
    <mat-form-field appearance="outline">
      <input matInput />
    </mat-form-field>
    <label>Material Type</label>
    <mat-form-field appearance="outline">
      <input matInput />
    </mat-form-field>
    <label>Truck Load Count</label>
    <mat-form-field appearance="outline">
      <input matInput />
    </mat-form-field>
    <label>Comments</label>
    <mat-form-field appearance="outline">
      <textarea matInput></textarea>
    </mat-form-field>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button >Cancel</button>
	<button mat-flat-button color="primary">Save</button>
</div>

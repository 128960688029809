import { Injectable } from "@angular/core";
import { Color } from "@lib/color";
import { DateTime } from "@lib/date-time";
import { BehaviorSubject, Observable, OverrideSubject, Subject, combineLatest, debounceTime, firstValueFrom, interval, map, of, op, switchMap } from "@lib/rxjs";
import { storageLocal } from "@lib/storage-local";
import { DataIntervalTag, Device, RemoveDataInterval, Shift, TimelineCustomSizes, User, fragQuery } from "@models";
import { WebClient } from "./web-client";
import { DataIntervalTagEditDialog } from "@app/shared/edit-dialog/data-interval-tag/data-interval-tag-edit-dialog.component";
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { percentageDefaultColors, percentageNewColors } from "src/colors";
import { User$ } from "./login.service";
import { chart } from "@lib/angular/chart";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UnitSystem } from "@lib/measurement/types";
import { measurement } from "@lib/measurement";
import { MeasurementTypeToUnit } from "@lib/angular/measurement/measurement-type-to-unit";

interface DurationOption {
    label: string;
    type: DateTime.Step | 'custom' | 'shifts' | 'week';
    key?: string;
}

const durationOptions: DurationOption[] = [
    {
        label: 'last hour',
        type: [1, 'hour'],
    },
    {
        label: 'Last 3 Hours',
        type: [3, 'hour'],
    },
    {
        label: 'Last 6 Hours',
        type: [6, 'hour'],
    },
    {
        label: 'Last 12 Hours',
        type: [12, 'hour'],
    },
    {
        label: 'Last 24 Hours',
        type: [1, 'day'],
    },
    {
        label: 'Week',
        type: 'week',
    },
    {
        label: 'Custom',
        type: 'custom',
    },
    {
        label: 'Shifts',
        type: 'shifts'
    }
];
for (const opt of durationOptions)
    opt.key = Array.isArray(opt.type) ? opt.type.join('') : opt.type;
const durationOptionMap = new Map(durationOptions.map((v) => [v.key, v]));

@Injectable({
    providedIn: "root",
})
export class FragQueryService {
    constructor(
        private readonly dialog: DialogService,
        private readonly webClient: WebClient,
        private readonly user: User,
        private readonly user$: User$,
        private snackBar: MatSnackBar,
        private readonly measurementTypeToUnit: MeasurementTypeToUnit
    ) {
        this.init();
        this.registerUnloadListener();
    }

    private unitAbbreviations: { [key: string]: string } = {
        'millimeter': 'mm',
        'centimeter': 'cm',
        'meter': 'm',
        'inch': 'in'
    };

    private unitSystem: { [key: string]: UnitSystem } = {
        'millimeter': 'smallMetricUnit',
        'centimeter': 'mediumMetricUnit',
        'meter': 'largeMetricUnit',
        'inch': 'imperialUnit',
    }

    private QuantitySystems: { [key: string]: UnitSystem } = {
        'meter': 'metricUnit',
        'yard': 'imperialUnit',
    }

    private registerUnloadListener() {
        window.addEventListener('beforeunload', this.clearLocalStorage);
    }

    private clearLocalStorage() {
        localStorage.removeItem("frag-camera-frag-query-duration");
        localStorage.removeItem("frag-camera-frag-query-begin");
        localStorage.removeItem("frag-camera-frag-query-end");
    }

    private liveTimeRange(step: DateTime.Step) {
        return interval(5 * 60 * 1000)
            .pipe(
                op.startWith(-1),
                op.map(() => {
                    const end = new DateTime();
                    this.end$.next(end)
                    const begin = end.clone().sub(step);
                    this.begin$.next(begin)
                    return {
                        begin,
                        end,
                    };
                }));
    }

    //==================== analysis page data variables ==============//
    public trigger$ = new BehaviorSubject<void>(null);

    public readonly devices$ = this.trigger$.pipe(
        switchMap(() => this.webClient.model.device.search$({
            sort: {
                clientId: '+',
                id: '+'
            }
        }))
    );

    public readonly defaultDevice$ = this.devices$.pipe(
        op.map((v) => v?.[0] ?? null)
    );

    public readonly device$ = new OverrideSubject(this.defaultDevice$);
    public readonly currentInterval = new BehaviorSubject(null);
    public readonly begin$ = new BehaviorSubject(new DateTime(storageLocal.get('frag-query-begin') ?? (DateTime.now() - (60 * 60 * 1000))));
    public readonly end$ = new BehaviorSubject(new DateTime(storageLocal.get('frag-query-end') ?? DateTime.now()));
    public readonly customShiftDate$ = new BehaviorSubject(new DateTime(DateTime.now()));
    public readonly weightType$ = new BehaviorSubject<'area' | 'volume'>(storageLocal.get('frag-query-weight-type') ?? 'volume');
    public readonly chartType$ = new BehaviorSubject<'line' | 'series'>(storageLocal.get('frag-query-chart-type') ?? 'line');
    public readonly intervalsLength = new BehaviorSubject<number>(0);
    public readonly currentIndex = new BehaviorSubject<number>(0);
    public readonly addDataAt = new BehaviorSubject<string>(null);
    public readonly filterIntervalLength = new BehaviorSubject<number>(0);
    public readonly totalIntervalLength = new BehaviorSubject<number>(0);
    public readonly currentData = new BehaviorSubject<chart.line.Series[]>(null);

    public readonly particleSizeUnit$ = this.user$.pipe(
        op.map(user => user.particleUnit),
        op.map(unit => this.unitAbbreviations[unit]),
    );

    public readonly particleSizeUnitSystem$ = this.user$.pipe(
        op.map(user => user.particleUnit),
        op.map(unit => this.unitSystem[unit]),
    );


    public readonly QuantityUnitSystem$ = this.user$.pipe(
        op.map(user => user.quantityUnit),
        op.map(unit => this.QuantitySystems[unit]),
    );


    public readonly QuantityTitle$ = combineLatest([this.user$, this.weightType$])
        .pipe(
            op.map(([user, weightType]) => {
                if (weightType === 'area')
                    return `Area ${user.quantityUnit === 'meter' ? '(m²)' : '(yard²)'}`;
                return `Volume ${user.quantityUnit === 'meter' ? '(m³)' : '(yard³)'}`;

            })
        );
    public readonly payloadUnitAbbreviation$ = this.QuantityUnitSystem$.pipe(
        op.map(unitSystem => {
            if (unitSystem == 'metricUnit') {
                return measurement.abbreviation('metricton');
            } else {
                return measurement.abbreviation('shortton');
            }
        })
    )

    public readonly shifts$: Observable<Shift[]> = this.device$.pipe(
        op.switchMap(device => {
            return this.webClient.model.shift.search$({
                where: {
                    deviceId: device.id
                },
                sort: {
                    id: '+'
                }
            })
        })
    );

    public readonly defaultshift$ = this.shifts$.pipe(
        op.map((v) => v?.[0] ?? null)
    );

    public readonly shift$ = new OverrideSubject(this.defaultshift$);

    public readonly durationOptions = this.shifts$.pipe(
        op.map(shifts => {
            if (shifts.length > 0) {
                return durationOptions;
            } else {
                return durationOptions.filter(option => option.type !== 'shifts')
            }
        })
    )

    public defaultDurationOption = this.durationOptions.pipe(
        op.map(options => {
            if (options.includes(durationOptionMap.get(storageLocal.get('frag-query-duration')))) {
                return durationOptionMap.get(storageLocal.get('frag-query-duration')).key
            } else {
                return '1hour'
            }
        })
    );

    public readonly duration$ = new OverrideSubject(this.defaultDurationOption);

    public readonly timeRange$ = this.device$.pipe(
        op.switchMap(device => {
            if (device) {
                return this.duration$.pipe(
                    map((key) => durationOptionMap.get(key)),
                    op.switchMap(durOpt => {
                        if (durOpt.type === 'custom') {
                            return combineLatest([this.begin$, this.end$]).pipe(
                                map(([begin, end]) => ({ begin, end }))
                            );
                        } else if (durOpt.type === 'shifts') {
                            return this.durationOptions.pipe(
                                op.switchMap(options => {
                                    if (options.includes(durationOptionMap.get('shifts'))) {
                                        return this.shift$.pipe(
                                            op.switchMap(shift => {
                                                return this.customShiftDate$.pipe(
                                                    map(customDate => {
                                                        let begin = new DateTime(customDate.setHours(
                                                            new DateTime(shift.begin * 1000).getHours(),
                                                            new DateTime(shift.begin * 1000).getMinutes(),
                                                        ));
                                                        let end = new DateTime(customDate.setHours(
                                                            new DateTime(shift.end * 1000).getHours(),
                                                            new DateTime(shift.end * 1000).getMinutes(),
                                                        ));
                                                        if (end < begin) {
                                                            end = new DateTime(end.getTime() + 24 * 60 * 60 * 1000);
                                                        }
                                                        return { begin, end };
                                                    })
                                                );
                                            })
                                        );
                                    } else {
                                        this.duration$.next('custom');
                                        return combineLatest([this.begin$, this.end$]).pipe(
                                            op.map(([begin, end]) => ({ begin, end }))
                                        );
                                    }
                                })
                            );
                        } else if (durOpt.type === 'week') {
                            return combineLatest([this.begin$, this.end$]).pipe(
                              map(([begin, end]) => {                                                                                          
                                end = new DateTime(end.getTime() + 24 * 60 * 60 * 1000 - 1000);                                                                                                                                                                               
                                return { begin, end };
                              })
                            );
                          }
                          
                        const step = durOpt.type;
                        return this.liveTimeRange(step);
                    })
                );
            } else {
                return null;
            }
        })
    );

    //===================== export data variables =====================//
    public readonly apiToken$ = new BehaviorSubject<string>(this.user.apiToken);
    public readonly tokenName$ = new BehaviorSubject<string>(this.user.tokenName);
    public exportReport = false;

    //=================================================================//

    public readonly percents$ = new BehaviorSubject(
        new Map(
            storageLocal
                .get<[number, string][]>('frag-query-percents')
                ?.filter(([p]) => percentageDefaultColors.has(p))
                .map(([p, c]) => [
                    p,
                    Color.html(c) ?? percentageDefaultColors.get(p),
                ]) ?? [10, 20, 50, 80].map((p) => [p, percentageDefaultColors.get(p)])
        )
    );

    public readonly newPercents$ = new BehaviorSubject(
        new Map(
            storageLocal
                .get<[number, string][]>('frag-query-percents')
                ?.filter(([p]) => percentageNewColors.has(p))
                .map(([p, c]) => [p, c ?? percentageNewColors.get(p)]) ??
            [10, 20, 50, 80].map((p) => [p, percentageNewColors.get(p)])
        )
    );

    public readonly customSizes$ = this.device$.pipe(
        map(device => {
            if (device.timelineCustomSizes) {
                const customSizes = JSON.parse(device.timelineCustomSizes) as TimelineCustomSizes[];
                let customSizeMap = new Map<number, TimelineCustomSizes>;
                if (customSizes.length) {
                    customSizes.forEach(customSize => {
                        customSizeMap.set(customSize.size, customSize);
                    })
                }
                return customSizeMap;
            } else {
                return null;
            }
        })
    );


    public readonly timelineRaw$ =
        combineLatest([
            this.device$.pipe(op.filter(v => !!v)),
            this.timeRange$,
            this.weightType$])
            .pipe(
                op.debounceTime(0),
                op.switchMap(([device, timeRange, weightType]) => {
                    const body: fragQuery.timeline.raw.Body = {
                        email: this.user.email,
                        authToken: this.user.authToken,
                        deviceId: device.id,
                        begin: timeRange.begin,
                        end: timeRange.end,
                        weightType,
                    };
                    return this.webClient.rest$({
                        route: 'frag-query/timeline/raw',
                        method: 'get',
                        body,
                    }).pipe(op.startWith(undefined));
                }),
                op.traverse(r =>
                    r.toRecord<fragQuery.timeline.quantity.Response>()),
                op.traverse(r => ({
                    begin: new DateTime(r.begin),
                    end: new DateTime(r.end),
                    intervals: fixDates(r.intervals, this.user.timezone, this.user.timezoneOffset),
                    tags: fixTags(r.tags),
                })),
                op.shareReplay(1));

    public readonly addOnTimelineQuantity$ = combineLatest([
        this.device$.pipe(op.filter((v) => !!v)),
        this.timeRange$,
        this.weightType$,
        this.addDataAt
    ]).pipe(
        op.debounceTime(0),
        op.switchMap(([device, timeRange, weightType, addDataAt]) => {
            let newTimeRange;
            const begin = timeRange.begin.valueOf();
            const end = timeRange.end.valueOf();
            const newduration = (end - begin) * 0.2;
            if (addDataAt !== null) {
                if (addDataAt === 'begin') {

                    newTimeRange = {
                        begin: new DateTime((begin - newduration)),
                        end: new DateTime(begin)
                    }
                    timeRange.begin = new DateTime((begin - newduration)),
                        timeRange.end = new DateTime(end)
                }
                if (addDataAt === 'end') {
                    newTimeRange = {
                        begin: new DateTime(end),
                        end: new DateTime(end + newduration)
                    }
                    timeRange.begin = new DateTime(begin);
                    timeRange.end = new DateTime(end + newduration)
                }
            }
            if (newTimeRange) {
                const body: fragQuery.timeline.quantity.Body = {
                    authToken: this.user.authToken,
                    email: this.user.email,
                    deviceId: device.id,
                    begin: newTimeRange.begin,
                    end: newTimeRange.end,
                    weightType,
                };

                return this.webClient
                    .rest$({
                        route: 'frag-query/timeline/quantity',
                        method: 'get',
                        body,
                    })
                    .pipe(op.startWith(undefined));
            } else {
                return of(null);
            }
        }),
        op.filter(res => res !== undefined),
        op.traverse((r) => r.toRecord<fragQuery.timeline.quantity.Response>()),
        op.traverse((r) => ({
            begin: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.begin) : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
            end: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.end) : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
            intervals: fixDates(r.intervals, this.user.timezone, this.user.timezoneOffset),
            tags: fixTags(r.tags),
        })),
        op.shareReplay(1)
    );

    public readonly timelineQuantity$ = combineLatest([
        this.device$.pipe(op.filter((v) => !!v)),
        this.timeRange$,
        this.weightType$,
    ]).pipe(
        op.debounceTime(0),
        op.switchMap(([device, timeRange, weightType]) => {
            const body: fragQuery.timeline.quantity.Body = {
                email: this.user.email,
                authToken: this.user.authToken,
                deviceId: device.id,
                begin: timeRange.begin,
                end: timeRange.end,
                weightType,
            };
            return this.webClient
                .rest$({
                    route: 'frag-query/timeline/quantity',
                    method: 'get',
                    body,
                })
                .pipe(op.startWith(undefined));
        }),
        op.traverse((r) => r.toRecord<fragQuery.timeline.quantity.Response>()),
        op.traverse((r) => ({
            begin: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.begin) : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
            end: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.end) : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
            intervals: fixDates(r.intervals, this.user.timezone, this.user.timezoneOffset),
            tags: fixTags(r.tags),
        })),
        op.shareReplay(1)
    );

    private readonly _percents$ = this.newPercents$.pipe(
        op.map((v) => [...v.keys()]),
    );

    private readonly addOnTimelineSieveSizesAtPercentsBody$ =
        combineLatest([
            this.device$.pipe(op.filter(v => !!v)),
            this.timeRange$,
            this.weightType$,
            this._percents$,
            this.addDataAt
        ])
            .pipe(
                op.map(([device, timeRange, weightType, percents, addDataAt]) => {
                    if (!percents.includes(0))
                        percents = [0, ...percents];
                    let newTimeRange;
                    const begin = timeRange.begin.valueOf();
                    const end = timeRange.end.valueOf();
                    const newduration = (end - begin) * 0.2;
                    if (addDataAt !== null) {
                        if (addDataAt === 'begin') {

                            newTimeRange = {
                                begin: new DateTime((begin - newduration)),
                                end: new DateTime(begin)
                            }
                            timeRange.begin = new DateTime((begin - newduration)),
                                timeRange.end = new DateTime(end)
                        }
                        if (addDataAt === 'end') {
                            newTimeRange = {
                                begin: new DateTime(end),
                                end: new DateTime(end + newduration)
                            }
                            timeRange.begin = new DateTime(begin);
                            timeRange.end = new DateTime(end + newduration)
                        }
                        const body: fragQuery.timeline.sieveSizesAtPercents.Body = {
                            authToken: this.user.authToken,
                            email: this.user.email,
                            deviceId: device.id,
                            begin: newTimeRange.begin,
                            end: newTimeRange.end,
                            weightType: weightType,
                            percents: percents,
                        };
                        return body;
                    }
                    return null;
                }));

    public readonly addOnTimelineSieveSizesAtPercents$ = this.addOnTimelineSieveSizesAtPercentsBody$
        .pipe(
            op.switchMap((body) => {
                if (body) {
                    return this.webClient.rest$({
                        route: 'frag-query/timeline/sieve-sizes-at-percents',
                        method: 'get',
                        body,
                    }).pipe(op.startWith(undefined));
                } else {
                    return of(null);
                }
            }),
            op.traverse(r =>
                r.toRecord<fragQuery.timeline.sieveSizesAtPercents.Response>()),
            op.traverse(r => ({
                deviceId: r.deviceId,
                begin: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.begin) : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
                end: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.end) : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
                percents: r.percents,
                intervals: fixDates(r.intervals, this.user.timezone, this.user.timezoneOffset),
                tags: fixTags(r.tags),
                summary: r.summary,
            })),
            op.tap(r => {
                if (r && r.intervals && r.intervals.length > 0) {
                    this.currentIndex.next(0);
                }
            }),
            op.shareReplay(1));

    private readonly timelineSieveSizesAtPercentsBody$ =
        combineLatest([
            this.device$.pipe(op.filter(v => !!v)),
            this.timeRange$,
            this.weightType$,
            this._percents$,
        ])
            .pipe(
                op.debounceTime(100),
                op.map(([device, timeRange, weightType, percents]) => {
                    const beginDate = new Date(timeRange.begin);
                    const endDate = new Date(timeRange.end);
                    const durationMonths = (endDate.getFullYear() - beginDate.getFullYear()) * 12 + endDate.getMonth() - beginDate.getMonth();

                    if (durationMonths >= 4) {
                        this.snackBar.open('Please select a duration of up to 4 months.', 'OK', {
                            duration: 5000,
                            panelClass: ['snackbar-success']
                        });
                        return null;
                    }
                    if (!percents.includes(0))
                        percents = [0, ...percents];

                    const body: fragQuery.timeline.sieveSizesAtPercents.Body = {
                        email: this.user.email,
                        authToken: this.user.authToken,
                        deviceId: device.id,
                        begin: timeRange.begin,
                        end: timeRange.end,
                        weightType: weightType,
                        percents: percents,
                    };
                    return body;
                }),
                op.filter(body => !!body)
            );

    public readonly timelineSieveSizesAtPercents$ = this.timelineSieveSizesAtPercentsBody$
        .pipe(
            op.switchMap((body) => {
                return this.webClient.rest$({
                    route: 'frag-query/timeline/sieve-sizes-at-percents',
                    method: 'get',
                    body,
                }).pipe(op.startWith(undefined));
            }),
            op.traverse(r =>
                r.toRecord<fragQuery.timeline.sieveSizesAtPercents.Response>()),
            op.traverse(r => ({
                deviceId: r.deviceId,
                begin: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.begin) : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
                end: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.end) : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
                percents: r.percents,
                intervals: fixDates(r.intervals, this.user.timezone, this.user.timezoneOffset),
                tags: fixTags(r.tags),
                summary: r.summary,
            })),
            op.tap(r => {
                this.addDataAt.next(null);
                storageLocal.set('scrollLeft', 0);
                if (r && r.intervals && r.intervals.length > 0) {
                    this.currentIndex.next(0);
                    this.intervalsLength.next(r.intervals.length);
                    this.filterIntervalLength.next((r.end.valueOf() - r.begin.valueOf()))
                    this.totalIntervalLength.next((r.end.valueOf() - r.begin.valueOf()))
                }
            }),
            op.shareReplay(1));

    private readonly exportTimelineSieveSizesAtPercentsBody$ = combineLatest([
        this.device$.pipe(op.filter(v => !!v)),
        this.timeRange$,
        this.weightType$,
        this._percents$,
    ]).pipe(
        debounceTime(100),
        map(([device, timeRange, weightType, percents]) => {
            if (!percents.includes(0)) percents = [0, ...percents];
            const begin = timeRange.begin;
            const end = timeRange.end;
            const twoWeeksInMillis = 1000 * 60 * 60 * 24 * 7 * 2;
            const durationInMillis = Math.abs(end.valueOf() - begin.valueOf());
            if (durationInMillis > twoWeeksInMillis) {
                this.snackBar.open('Please select a duration of up to two weeks.', 'OK', {
                    duration: 5000,
                    panelClass: ['snackbar-success']
                });
                return null;
            }


            const body: fragQuery.timeline.sieveSizesAtPercents.Body = {
                email: this.user.email,
                authToken: this.user.authToken,
                deviceId: device.id,
                begin: timeRange.begin,
                end: timeRange.end,
                weightType: weightType,
                percents: percents,
            };

            return body;
        }),
        op.filter(body => !!body)
    );


    public readonly timelineSieveSizesAtPercentsUrl$ = this.timelineSieveSizesAtPercentsBody$.pipe(
        op.debounceTime(0),
        map((body) => {
            const modifiedBody = {
                apiKey: this.user.apiToken,
                deviceId: body.deviceId,
                begin: body.begin,
                end: body.end,
                weightType: body.weightType,
                percents: body.percents,
            };
            return this.webClient.restUrl({
                route: 'frag-query/timeline/sieve-sizes-at-percents',
                method: 'get',
                body: modifiedBody,
            });
        })
    );

    public totalWeight$ = this.timelineSieveSizesAtPercents$.pipe(
        op.map(input => (input && input.summary && input.summary.totalWeight) ? input.summary.totalWeight.toFixed(2) : null)
    );

    public totalPayload$: Observable<number> = combineLatest(
        [
            this.timelineSieveSizesAtPercents$,
            this.totalWeight$,
            this.QuantityUnitSystem$
        ]
    ).pipe(
        map(([input, totalWeight, quantityUnitSystem]) => {
            if (input && input.summary && totalWeight) {
                const totalVolume = parseFloat(totalWeight);
                const typeToUnit = this.measurementTypeToUnit.getUnitsForSystem(quantityUnitSystem);
                let density;
                this.device$.subscribe(device => {
                    density = device.density
                });
                const densityAfterUnitConversion = measurement.convertFromBaseUnits(measurement.convertToBaseUnits(density, 'kilogramPerMeter3'), typeToUnit.largeDensity);
                const totalPayload = totalVolume * densityAfterUnitConversion;
                return parseFloat(totalPayload.toFixed(2));
            }
            return null;
        })
    )

    private readonly liveDataIntervalTagTrigger$ = new Subject<void>();
    public readonly liveDataIntervalTag$ = this.liveDataIntervalTagTrigger$.pipe(
        op.startWith(0),
        op.switchMap(() => this.device$),
        op.switchMap((device) =>
            this.webClient.model.dataIntervalTag.getLive$(device.id)
        ),
        op.shareReplay(1)
    );

    public init() {
        this.devices$.subscribe((devices) => {
            let device: Device = null;
            if (devices) {
                const uuid = '' + storageLocal.get('frag-query-device-uuid');
                device = devices.find((device) => device.uuid === uuid);
                if (!device) device = devices[0];
            }
            this.device$.next(device);
        });
        this.device$.subscribe(device => {
            if (device)
                storageLocal.set('frag-query-device-uuid', device?.uuid ?? null);
        });

        this.duration$.subscribe((v) => storageLocal.set('frag-query-duration', v));

        this.begin$.subscribe((v) => storageLocal.set('frag-query-begin', v));
        this.end$.subscribe((v) => storageLocal.set('frag-query-end', v));
        this.weightType$.subscribe((v) =>
            storageLocal.set('frag-query-weight-type', v)
        );
        this.chartType$.subscribe((v) =>
            storageLocal.set('frag-query-chart-type', v)
        );
        this.newPercents$.subscribe((v) =>
            storageLocal.set('frag-query-percents', [...v])
        );
    }
    public async setLiveTag(tag: DataIntervalTag) {
        const response = await this.webClient.model.dataIntervalTag.setLiveTag(tag);
        this.liveDataIntervalTagTrigger$.next();
        return response
    }

    public async openLiveTagDialog(newTag?: boolean) {
        const device = await firstValueFrom(this.device$);
        let tag: DataIntervalTag;
        if (!newTag) {
            tag = await firstValueFrom(this.liveDataIntervalTag$);
        }
        tag = tag?.clone() ?? new DataIntervalTag();
        tag.deviceId = device.id;

        if (
            await this.dialog.open(DataIntervalTagEditDialog, {
                record: tag,
                showTimes: false,
            })
        ) {
            return await this.dialog.await(this.setLiveTag(tag));
        } else return false
    }

    public async exportTimelineSieveSizesAtPercents() {
        const body = await firstValueFrom(this.exportTimelineSieveSizesAtPercentsBody$);
        const reponse = await this.webClient.rest({
            route: 'frag-query/timeline/sieve-sizes-at-percents',
            method: 'get',
            body,
        });
        const r = reponse.toRecord<fragQuery.timeline.sieveSizesAtPercents.Response>();
        return {
            deviceId: r.deviceId,
            begin: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.begin) : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
            end: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.end) : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
            percents: r.percents,
            intervals: fixDates(r.intervals, this.user.timezone, this.user.timezoneOffset),
            tags: fixTags(r.tags),
            summary: r.summary,
        };
    }

  public async removeDataIntervals(dataIntervals: number[]) {
    let deviceId: number;
    this.device$.subscribe((device) => {
      deviceId = device.id;
    });
    try {
      const response = await this.webClient.rest({
        route: 'data-interval/remove',
        method: 'post',
        body: {
          email: this.user.email,
          authToken: this.user.authToken,
          deviceId,
          dataIntervalIds: dataIntervals,
        },
      });
      const responceRecord = response.toRecord<RemoveDataInterval.Response>();

      this.snackBar.open(`${responceRecord.success} interval(s) removed successfully!`, 'OK', {
        duration: 5000,
        panelClass: ['snackbar-success'],
      });

      if (responceRecord.success){
          this.trigger$.next();
      }

      return responceRecord;
    } catch (error) {
      console.error('Error deleting data intervals:', error);
      
      this.snackBar.open(
        'Failed to delete interval(s). Please try again.',
        'OK',
        {
          duration: 5000, 
          panelClass: ['snackbar-error'],
        }
      );

      return null; 
    }
  }

  public readonly exportTimelineSieveSizesAtPercents$ =
    this.exportTimelineSieveSizesAtPercentsBody$.pipe(
      op.switchMap((body) => {
        return this.webClient
          .rest$({
            route: 'frag-query/timeline/sieve-sizes-at-percents',
            method: 'get',
            body,
          })
          .pipe(op.startWith(undefined));
      }),
      op.traverse((r) =>
        r.toRecord<fragQuery.timeline.sieveSizesAtPercents.Response>()
      ),
      op.traverse((r) => ({
        deviceId: r.deviceId,
        begin:
          this.user.timezone === null || this.user.timezone === 'Default'
            ? new DateTime(r.begin)
            : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
        end:
          this.user.timezone === null || this.user.timezone === 'Default'
            ? new DateTime(r.end)
            : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
        percents: r.percents,
        intervals: fixDates(
          r.intervals,
          this.user.timezone,
          this.user.timezoneOffset
        ),
        tags: fixTags(r.tags),
        summary: r.summary,
      })),
      op.shareReplay(1)
    );
}

function getTimeOffset(offsetString: string) {
    if (offsetString === null) {
        return null;
    } else {
        const offset = offsetString.slice(3);
        const hourTime = parseInt(offset.split(':')[0].slice(1, 3)) * 60;
        const minuteTime = parseInt(offset.split(':')[1].slice(0, 2));
        const totalOffset = hourTime + minuteTime;
        const formattedNumber = offsetString.slice(3, 4) === '+' ? `+${totalOffset}` : `-${totalOffset}`;
        return parseInt(formattedNumber);
    }
}

function fixDates<
    T extends {
        begin: DateTime;
        end: DateTime;
    }
>(intervals: T[], timezone: string, offset: number) {
    for (const interval of intervals) {
        interval.begin =
            timezone === null || timezone === "Default"
                ? new DateTime(interval.begin)
                : new DateTime(interval.begin).replaceTimezone(offset);
        interval.end =
            timezone === null || timezone === "Default"
                ? new DateTime(interval.end)
                : new DateTime(interval.end).replaceTimezone(offset);
    }
    return intervals;
}

function fixTags(tags: DataIntervalTag.RestTypes['response']['Get'][]) {
    return tags.map((v) => {
        const tag = new DataIntervalTag();
        DataIntervalTag.meta.copyToInstance(v, tag);
        return tag;
    });
}

export namespace FragQueryService { }

<div mat-dialog-header class="title">
    Remove Data Intervals
</div>
<div mat-dialog-content>
    <div *ngIf="(dataIntervals$ | async)" class="full-width content-container">
        <div class="flex-row end">
            <div class="centered-content">Selected: <b>{{ selectedRecords.size }} </b> |   Select All</div>
            <div>
                <mat-checkbox color="primary" [ngModel]="selectedRecords.size === (dataIntervals$ | async)?.length"
                (ngModelChange)="selectAll($event)">
            </mat-checkbox>
        </div>
    </div>
    <div *ngIf="(dataIntervals$ | async) === undefined" class="container centered-content">
        <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
    </div>
    <div *ngIf="(dataIntervals$ | async) === null" class="container centered-content">
        <div class="container centered-content">
            <div>
                <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
            </div>
            <div>No Data Found</div>
        </div>
    </div>
        <div class="grid-3xn grid-container scroll">
            <div class="container" *ngFor="let interval of (dataIntervals$ | async)">
                <data-interval-selector [interval]="interval" [checked]="selectedRecords.has(interval)" (selected)="selectRecord($event, interval)">
                </data-interval-selector>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-flat-button color="warn" [disabled]="!valid()" (click)="remove()">Remove</button>
    </div>
</div>
<div class="flex-row full-width">
    <div class="flex-column top even">
        <div class="flex-row">
            <div class="titlespan">Begin: </div>
            <div class="small-card-content primary date-value">
                {{_interval.begin | date : getFormattedDateTime()}}
            </div>
        </div>
        <div class="flex-row">
            <div class="titlespan">End: </div> 
            <div class="small-card-content primary date-value">
                {{_interval.end | date : getFormattedDateTime()}}
            </div>
        </div>
    </div>
    <div class="interval-selector">
        <mat-checkbox color="primary" [ngModel]="_checked" (ngModelChange)="onCheck()" class="overlay-checkbox">
        </mat-checkbox>
        <img onerror="this.src='../../../assets/image_not_found.jpg'"
        height="100px"
        (click)="showFullImg(_interval.imageUrl)" [src]="_interval.imageUrl" alt="Slide image" />
    </div>
    <ng-template #fullimgtemplate>
        <div>
            <button mat-icon-button class="close-btn" (click)="closeFullImg()">
                <mat-icon>close</mat-icon>
            </button>
            <img [src]="_interval.imageUrl" class="full-height full-width" />
        </div>
    </ng-template>
</div>
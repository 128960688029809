import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogConfig,MatDialogRef} from '@angular/material/dialog';
import {DataIntervalTag} from '@models';
import { FragQueryService } from 'src/injectables/frag-query.service';

interface Input{
	record:DataIntervalTag;
	showTimes?:boolean;
}

@Component({
	selector: 'data-interval-tag-edit-dialog',
	templateUrl: './data-interval-tag-edit-dialog.component.html',
	styleUrls: [
		'./data-interval-tag-edit-dialog.component.scss'
	]
})
export class DataIntervalTagEditDialog implements OnInit{
	static readonly config:MatDialogConfig<Input>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;

	constructor(
		private readonly host:MatDialogRef<DataIntervalTagEditDialog,boolean>,
		private readonly fragQuery:FragQueryService,
		@Inject(MAT_DIALOG_DATA) private readonly input:Input,
	){
	}

	public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
	public showTimes=this.input.showTimes ?? true;

	public record=this.input.record;
	public begin=this.record.beginDateTime;
	public end=this.record.endDateTime;
	public toleranceSpecSize = this.record.toleranceSpecSize;
	public benchHeight=this.record.benchHeightSize;
	public benchSeamSize=this.record.benchSeamSize;
	public materialType=this.record.materialType;
	public truckLoadCount=this.record.truckLoadCount;
	public comments=this.record.comments;

	public ngOnInit(){
	}

	public valid(){
		if(!this.toleranceSpecSize)
			return false;
		return true;
	}

	public cancel(){
		this.host.close(false);
	}

	public save(){
		this.record.beginDateTime=this.begin;
		this.record.endDateTime=this.end;
		this.record.toleranceSpecSize = this.toleranceSpecSize;
		this.record.benchHeightSize=this.benchHeight;
		this.record.benchSeamSize=this.benchSeamSize;
		this.record.materialType=this.materialType;
		this.record.truckLoadCount=this.truckLoadCount;
		this.record.comments=this.comments;
		this.host.close(true);
	}
}

<model-table
    [model]="model"
    [sort]="{ begin: '-' }"
    [restClient]="restClient"
    [editDialog]="editDialog"
    [where]="where$ | async"
    (add)="onAdd($event)"
 >
    <div class="settings">
        <mat-form-field class="device">
            <mat-label> Device </mat-label>
            <mat-select [value]="device$ | async" (valueChange)="device$.next($event)">
                <mat-option *ngFor="let device of devices$ | async" [value]="device">
                    {{ device.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-button (click)="openLiveTagDialog()">
            <mat-icon>tag</mat-icon> Set Live Tag
        </button>
    </div>
    <ng-template model-table-header>
        <th></th>
        <th>Begin</th>
        <th>End</th>
        <th>Tolerance Spec</th>
        <th>Bench Height</th>
        <th>Bench Seam</th>
        <th>Material Type</th>
        <th>Truck Load Count</th>
        <th>Comments</th>
    </ng-template>
    <ng-template model-table-row [type]="model" let-record="record">
        <td>
            <button [matMenuTriggerFor]="menu" mat-stroked-button class="view-button">
                View
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openInDTimeline(record)">
                    PSD Timeline
                </button>
            </mat-menu>
        </td>
        <td>
            <div>
                {{ record.begin * 1000 | date : "d/M/yy HH:mm" }}
            </div>
        </td>
        <td>
            <div *ngIf="record.end">
                {{ record.end * 1000 | date : "d/M/yy HH:mm" }}
            </div>
            <div *ngIf="!record.end">---</div>
        </td>
        <td>
            <measurement type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="record.toleranceSpecSize"></measurement>
        </td>
        <td>
            <measurement type="distance" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="record.benchHeightSize"></measurement>
        </td>
        <td>
            <measurement type="distance" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="record.benchSeamSize"></measurement>
        </td>
        <td>
            {{ record.materialType }}
        </td>
        <td>
            <measurement type="count" [value]="record.truckLoadCount"></measurement>
        </td>
        <td class="comments">
            {{ record.comments }}
        </td>
    </ng-template>
</model-table>
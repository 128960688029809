import { Component, Input } from '@angular/core';
import { DateTime } from '@lib/date-time';

@Component({
  selector: 'image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
})
export class ImageContainerComponent {
  @Input() public imageSrc: string;
  @Input() public beginDate: DateTime;
  @Input() public endDate: DateTime;
  @Input() slabs: number = 0;
  @Input() thresholdCount: number = 0;
  @Input() dynamicClass: any = {};  
}

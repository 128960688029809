<div class="snapshot-container full-height">
    <mat-card-content class="full-height">
        <div class="flex-row justify height-10">
            <div class="full-width flex-row center middle">
                <p class="font-medium"><strong>Begin: </strong>{{beginDate|date:'medium'}}</p>
            </div>
            <div class="full-width flex-row center middle">
                <p class="font-medium"><strong>End: </strong>{{endDate|date:'medium'}}</p>
            </div>
        </div>
        <div class="flex-column center height-90 image-wrapper full-width">
          <img onerror="this.onerror=null; this.src='../../../assets/image_not_found.jpg'" class="slide" [src]="imageSrc"
            height="100%" [ngClass]="dynamicClass" />
            <div class="count-container" *ngIf="slabs || thresholdCount">
              <div class="count-box">
                  <mat-icon svgIcon="warn-yellow"></mat-icon>&nbsp;
                  <span>Slabs: {{ slabs }}</span>&nbsp;&nbsp;
                  <mat-icon svgIcon="warn-red"></mat-icon>&nbsp;
                  <span>Thresholds: {{ thresholdCount }}</span>
              </div>
          </div>  
        </div>
    </mat-card-content>
</div>